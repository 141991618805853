import Emitter from 'component-emitter';
import schemapack from 'schemapack';
///import { debugStoreApi } from 'storage/debug';

//let debugEnabled = debugStoreApi.getState().getSocketDebugEnabled();
//debugStoreApi.subscribe(() => {
//  debugEnabled = debugStoreApi.getState().getSocketDebugEnabled();
//});

const debugEnabled = false;

const logFilter = ['user/ping', 'user/pong'];

function getArgsFormatted(args) {
  return args.reduce((acc, curr, i) => {
    if (i === 0) acc.push('(');
    if (typeof curr === 'string') {
      acc.push(`"${curr}"`);
    } else {
      acc.push(curr);
    }
    if (i !== args.length - 1) acc.push(',');
    if (i === args.length - 1) acc.push(')');
    return acc;
  }, []);
}

function showOutgoingDebug(packet) {
  if (packet.data) {
    if (!debugEnabled) return;
    if (logFilter.includes(packet.data[0])) return;
    const params = [];
    params.push('OUT');
    if (packet.id) {
      params.push('id:');
      params.push(packet.id);
    }
    params.push(`"${packet.data[0]}"`);
    const args = packet.data.slice(1);

    const argsFormatted = getArgsFormatted(args);

    params.push(...argsFormatted);
    // eslint-disable-next-line no-console
    console.log(...params);
  }
}

function showIncommingDebug(packet) {
  if (packet.type === 2 || packet.type === 3) {
    if (!debugEnabled) return;
    const params = [];
    params.push('INN');

    let args = [];

    if (packet.type === 3) {
      params.push('id:');
      params.push(packet.id);
      args = packet.data;
    }

    if (packet.type === 2) {
      if (logFilter.includes(packet.data[0])) return;
      params.push(`"${packet.data[0]}"`);
      args = packet.data.slice(1);
    }
    const argsFormatted = getArgsFormatted(args);
    params.push(...argsFormatted);
    // eslint-disable-next-line no-console
    console.log(...params);
  }
}

const eventCoders = [];
function addCoder(event, dataSchema) {
  if (dataSchema) {
    const coder = schemapack.build({ _id: 'uint8', data: dataSchema });
    eventCoders.push({ event, coder });
  } else {
    const coder = schemapack.build({ _id: 'uint8' });
    eventCoders.push({ event, coder });
  }
}
class Encoder {
  encode(packet, callback) {
    showOutgoingDebug(packet);
    try {
      let binarySend = false;
      if (packet.data) {
        const eventCoderIndex = eventCoders.findIndex(eventCoder => eventCoder.event === packet.data[0]);
        if (eventCoderIndex > -1) {
          const eventCoder = eventCoders[eventCoderIndex];
          const binary = eventCoder.coder.encode({ _id: eventCoderIndex, data: packet.data[1] });
          binarySend = true;
          callback([binary]);
        }
      }
      if (!binarySend) {
        const reduced = { ...packet };
        reduced.d = reduced.data;
        delete reduced.data;
        delete reduced.nsp;
        delete reduced.options;
        if (reduced.type === 2) delete reduced.type;
        return callback([JSON.stringify(reduced)]);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('catched', JSON.stringify(packet, null, 2));
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
}

class Decoder extends Emitter {
  add(obj) {
    try {
      if (typeof obj === 'string') {
        const packet = JSON.parse(obj);
        if (packet.type === undefined && packet.d[0] === 'schema') {
          const coders = packet.d[1];
          coders.forEach(coder => {
            addCoder(coder[0], coder[1]);
          });
        } else {
          if (packet.type === undefined) packet.type = 2;
          packet.data = packet.d;
          delete packet.d;
          packet.nsp = '/';
          showIncommingDebug(packet);
          this.emit('decoded', packet);
        }
      } else {
        const view = new Uint8Array(obj);
        const eventCoderIndex = view[0];
        const eventCoder = eventCoders[eventCoderIndex];
        const decoded = eventCoders[eventCoderIndex].coder.decode(obj);
        const packet = {};
        packet.type = 2;
        packet.data = [eventCoder.event, decoded.data];
        packet.nsp = '/';
        showIncommingDebug(packet);
        this.emit('decoded', packet);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('catched', JSON.stringify(obj, null, 2));
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
  destroy() {}
}

export default { Encoder, Decoder };
