import Storage from 'utilities/storage';

const TOKEN = 'token'; // JSON Web Token
const storage = new Storage();
storage.init('live_user_1');
storage.defineEntry(TOKEN, null);

const sessionStorage = new Storage({ type: 'session' });
sessionStorage.init('live_user_1');
sessionStorage.defineEntry(TOKEN, null);

export default {
  setToken: token => {
    storage.setItem(TOKEN, token);
    sessionStorage.setItem(TOKEN, token);
  },
  getToken: () => {
    return sessionStorage.getItem(TOKEN) || storage.getItem(TOKEN);
  },
};
