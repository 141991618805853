import { capitalize } from 'helpers';
import { modelApi } from 'services/ModelService';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import React from 'react';


export function BooleanField(params) {
  const { instance, attributeName } = params;

  const label = capitalize(attributeName);

  const handleChange = (event, value) => {
    instance[attributeName] = value === true;
    modelApi.setState({ instance: { ...instance } });
  };

  return (
    <FormControlLabel
      control={<Checkbox checked={instance[attributeName]} onChange={handleChange} color="primary" />}
      label={label}
    />
  );
};
