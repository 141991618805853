import React, { useEffect, useState } from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useNotificationStore } from 'services/NotificationService';

export default function Notification() {
  const notification = useNotificationStore(state => state.notification);
  const [message, setMessage] = useState(null);
  const [open, setOpen] = useState(false);
  const [exited, setExited] = useState(true);
  useEffect(() => {
    if (notification !== message) {
      if (!exited) {
        setOpen(false);
      } else {
        setOpen(true);
        setExited(false);
        setMessage(notification);
      }
    }
  }, [notification, open, exited]);
  return (<>
    <Snackbar open={open} autoHideDuration={6000}
      onClose={() => {
        setOpen(false);
      }}
      onExited={() => {
        setExited(true);
      }}>
      {message &&
        <Alert onClose={() => {
          setOpen(false);
        }} severity={message.type}>
          {message.text}
        </Alert>
      }
    </Snackbar>
  </>);
}