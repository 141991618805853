import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import Budget from './components/Budget/Budget';
import TotalUsers from './components/TotalUsers/TotalUsers';
import TasksProgress from './components/TasksProgress/TasksProgress';
import TotalProfit from './components/TotalProfit/TotalProfit';
import LatestSales from './components/LatestSales/LatestSales';
import UsersByDevice from './components/UsersByDevice/UsersByDevice';
import LatestProducts from './components/LatestProducts/LatestProducts';
import LatestOrders from './components/LatestOrders/LatestOrders';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const Dashboard = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalUsers />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;

