import moment from 'moment-timezone';

const defaultTz = 'UTC';
const dateTime = 'YYYY-MM-DD HH:mm:ss';

export function utcToLocal(utcString, timezone) {
  return moment.tz(utcString, timezone || defaultTz).format(dateTime);
}

export function localToUtc(timeStamp, timezone) {
  const t = timeStamp.format(dateTime || defaultTz);
  return moment.tz(t, timezone).utc();
}
