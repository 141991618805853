import create from 'zustand';
export const [useNotificationStore, notificationApi] = create((set, get) => ({
  notification: null,
  showNotification: message => {
    set({
      notification: {
        type: 'info',
        text: message
      }
    });
  },
  showError: message => {
    set({
      notification: {
        type: 'error',
        text: message
      }
    });
  },
}));
