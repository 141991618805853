import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { modelApi } from 'services/ModelService';
import { capitalize } from '../../helpers';

export function GroupIdField({ instance, attributeName }) {
  const label = capitalize(attributeName);

  const options = instance.groups ? instance.groups.map(group => {
    return { id: group.group_id, label: group.group_name };
  }) : [];

  const value = options.find(o => o.id === instance[attributeName]);

  const handleChange = (event, option) => {
    instance[attributeName] = option == null ? null : option.id;
    modelApi.setState({ instance: { ...instance } });
  };

  return (
    <Autocomplete
      options={options}
      value={value}
      getOptionSelected={(option, value) => {
        return value ? option.id === value.id : null;
      }}
      getOptionLabel={option => {
        if (!option || !option.id) {
          return 'none';
        }
        return option.label;
      }}
      onChange={handleChange}
      renderOption={(option) => (
        <>
          {option.label}
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />

  );
}




