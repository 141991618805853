import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Button, Card, CardActions, CardContent, Divider, Grid, Typography, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import Toolbar from './components/Toolbar/Toolbar';
import { isAttributeHidden, modelApi, useModelStore } from 'services/ModelService';
import { capitalize } from 'helpers';
import { BooleanField } from './components/BooleanField';
import { FileField } from './components/FileField';
import { RelationField } from './components/RelationField';
import { DateTimeField } from './components/DateTimeField';
import { Loader } from './components/Loader';
import { ModelTextField } from './components/TextField';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  marginLeft: {
    'margin-left': '10px',
  },
}));


function pushGroup(schema, instance, keysArray, rows, timezone, groupName) {

  if (groupName) {
    rows.push(
      <Grid key={groupName} item md={12} xs={12}>
        <Typography style={{padding:'16px 0'}} variant="h5">{groupName}</Typography><Divider />
      </Grid>,
    );
  }

  keysArray.forEach((key) => {
    const attribute = schema.attributes[key];
    const hidden = isAttributeHidden(attribute, 'form');

    if (hidden) {
      return;
    }

    let type = attribute.type;
    if (type == null) {
      type = 'model';
    }

    let row = null;

    if (attribute.component && typeof attribute.component === 'function') {
      row = <attribute.component instance={instance} attribute={attribute} attributeName={key} />;
    } else {
      switch (type) {
        case 'boolean':
          row = <BooleanField instance={instance} attribute={attribute} attributeName={key} />;
          break;
        case 'file':
          row = <FileField instance={instance} attribute={attribute} attributeName={key} />;
          break;
        case 'richtext':
          row = <ModelTextField instance={instance} attribute={attribute} attributeName={key} />;
          break;
        case 'integer':
        case 'text':
        case 'string':
        case 'password':
          row = <ModelTextField instance={instance} attribute={attribute} attributeName={key} />;
          break;
        case 'model':
          row = <RelationField instance={instance} attribute={attribute} attributeName={key} />;
          break;
        case 'datetime':
          row = (
            <DateTimeField instance={instance} attribute={attribute} attributeName={key} timezone={timezone} />
          );
          break;
        default:
          return;
      }
    }

    if (row) {
      if(attribute.tooltip) {
        row = <Tooltip title={attribute.tooltip} placement="right" arrow><div>{row}</div></Tooltip>;
      }

      rows.push(
        <Grid key={key} item md={6} xs={12}>
          {row}
        </Grid>,
      );
      rows.push(<Grid key={key + '_'} item md={6} xs={12} />);
    }
  });
}


function Rows({ schema, instance }) {
  const rows = [];
  const keys = Object.keys(schema.attributes);
  const timezone = instance.event ? instance.event.timezone : instance.timezone;
  const sortedKeys = [];
  const unsortedKeys = [];

  if (schema.sortedGroups) {

    schema.sortedGroups.forEach(group => {
      const attributes = group.attributes;
      attributes.forEach(attribute => {
        sortedKeys.push(attribute);
      });
    });


    // => unsorted
    keys.forEach(key => {
      let isInSortedKeys = false;
      sortedKeys.forEach(sortedKey => {
        if (key === sortedKey) isInSortedKeys = true;
      });
      if (!isInSortedKeys) unsortedKeys.push(key);
    });
    pushGroup(schema, instance, unsortedKeys, rows, timezone, capitalize(schema.info.name));


    // => sorted
    schema.sortedGroups.forEach(group => {
      const groupName = group.name;
      const attributes = group.attributes;
      pushGroup(schema, instance, attributes, rows, timezone, groupName);
    });

  } else {

    pushGroup(schema, instance, keys, rows, timezone);

  }

  return rows;
}


const Form = props => {
  const { className, instance, ...rest } = props;
  const { fileName } = instance;
  const fileSelected = fileName !== '';

  const history = useHistory();

  const classes = useStyles();

  const schema = useModelStore(state => state.schema);

  const rows = <Rows schema={schema} instance={instance} />;
  const actionName = instance.id ? 'Update' : 'Create';

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const status = useModelStore(state => state.status);

  useEffect(() => {
    const _progress = status.progress * 100 / status.length;
    const value = !isNaN(_progress) ? _progress : null;
    if(value != null) {
      setProgress(value);
    }
  }, [status]);

  const handleSave = async () => {
    const state = modelApi.getState();

    if (schema.useFormData) {
      setLoading(true);
    }

    if (instance.id != null) {
      await state.update(instance);
    } else {
      await state.create(instance);
    }
    history.push(`/${schema.routeName}`);

    if (schema.kind === 'singleType') {
      // TODO: quick win to give feedback to the user
      window.location.reload();
    }
  };

  return (
    <div className={classes.root}>
      <Toolbar headline={actionName} hasSearch={false} />
      <Card {...rest} className={clsx(classes.root, className)}>
        <form autoComplete="off" noValidate>
          <CardContent>
            <Grid container spacing={3}>
              {rows}
            </Grid>
          </CardContent>
          <Divider />
          {loading ? <Loader progress={progress} /> : <CardActions>
            <Button color="primary" variant="contained" onClick={handleSave} disabled={!fileSelected}>
              {actionName}
            </Button>
          </CardActions>}
        </form>
      </Card>
    </div>
  );
};

const ModelForm = props => {
  const { id } = props;

  const schema = useModelStore(state => state.schema);
  const instance = useModelStore(state => state.instance);

  useEffect(() => {
    const state = modelApi.getState();
    if (id === 'new') {
      state.new();
    } else {
      state.get(id);
    }
    return () => {
      state.cancel();
    };
  }, [id]);

  return schema && instance ? <Form schema={schema} instance={instance} /> : null;
};

export default ModelForm;
