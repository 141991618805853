import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { modelApi } from 'services/ModelService';
import { useZoomStore, zoomApi } from 'services/ZoomService';
import { CircularProgress } from '@material-ui/core';

export function ZoomUserField({ instance, attributeName }) {
  const users = useZoomStore(state => (state.users != null ? state.users : []));
  const [allZoomUsersFetched, setAllZoomUsersFetched] = React.useState(false);
  const tokenIsGood = useZoomStore(state => state.tokenIsGood);

  useEffect(() => {
    async function fetchData() {
      await zoomApi.getState().get();
      setAllZoomUsersFetched(true);
    }

    fetchData();
  }, []);

  const options = users.map(user => {
    return { id: user.id, label: user.first_name + ' ' + user.last_name };
  });

  const value = options.find(o => o.id === instance[attributeName]);

  const handleChange = (event, option) => {
    instance[attributeName] = option == null ? null : option.id;
    modelApi.setState({ instance: { ...instance } });
  };

  if(!allZoomUsersFetched) {
    return <CircularProgress color="primary" size={50} />;
  }

  return (
    <Autocomplete
      options={options}
      value={value}
      disabled={!tokenIsGood}
      getOptionSelected={(option, value) => value ? option.id === value.id : null}
      getOptionLabel={option => {
        if (!option || !option.id) {
          return 'none';
        }
        return option.label;
      }}
      onChange={handleChange}
      renderOption={(option) => (
        <>
          {option.label}
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={attributeName}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />

  );
}




