import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  IconButton,
  Table as MuiTable,
  TableBody,
  TableCell,
  Button,
  TableHead,
  TableRow,
  TablePagination,
  CardMedia,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { capitalize } from 'helpers';
import { modelApi, useModelStore, isAttributeHidden } from 'services/ModelService';
import AlertDialog from '../AlertDialog/AlertDialog';

const useStyles = makeStyles(theme => {
  return {
    root: {},
    content: {
      padding: 0,
    },
    inner: {
      minWidth: 1050,
    },
    nameContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    avatar: {
      marginRight: theme.spacing(2),
    },
    actions: {
      justifyContent: 'flex-end',
    },
    headerSelectionToolbar: {
      background: theme.palette.grey['50'],
      position: 'absolute',
      display: 'block',
      top: 0,
      left: '42px',
      width: "calc(100% - 42px)",
      lineHeight: '1.5rem',
      padding: '10px',
    },
    headerSelectionButton: {
      color: theme.palette.icon,
    },
    media: {
      width: "300px",
      maxWidth: "400px",
    }
  };
});

const Table = props => {
  const { className, ...rest } = props;

  const schema = useModelStore(state => state.schema);
  const instances = useModelStore(state => state.instances);
  const totalCount = useModelStore(state => state.totalCount);
  const page = useModelStore(state => state.page);
  const pageSize = useModelStore(state => state.pageSize);
  const sortAttribute = useModelStore(state => state.sortAttribute);
  const sortOrder = useModelStore(state => state.sortOrder);

  const history = useHistory();

  useEffect(() => {
    const state = modelApi.getState();
    state.list();
    return () => {
      state.cancel();
    };
  }, [pageSize]);

  const getValue = (instance, columnName, attribute) => {
    let value;
    if (Object.prototype.hasOwnProperty.call(instance, columnName)) {
      let type;
      if (attribute) {
        if (attribute.valueInTable) {
          value = attribute.valueInTable(instance, columnName);
          if(value) {
            if (value.media) {
              if (value.asset) {
                const url = value.asset.url;
                //const fileName = value.asset.fileName;
                if (value.media === 'image') {
                  value = [
                    <CardMedia key={1} component={'img'} className={classes.media} image={value.asset.url} />,
                  ];
                } else if (value.media === 'video') {
                  value = [
                    <CardMedia key={1} component={'video'} className={classes.media} image={url + '#t=0.5'} controls />,
                  ];
                } else if (value.media === 'audio') {
                  value = [
                    <CardMedia key={1} component={'audio'} className={classes.media} image={value.asset.url} controls />,
                  ];
                } else {
                  value = value.asset.url;
                }
              } else {
                value = '-';
              }
            } else if(value.asset && value.asset.url) {
              value = value.asset.url;
            }
          } else {
            value = '-';
          }

        } else {
          value = instance[columnName];
          if (attribute.type != null) {
            type = attribute.type;
          } else if (attribute.model != null) {
            type = 'model';
          }
        }
      } else {
        // should only happen for 'id' and should always result in number, afaik.
        value = instance[columnName];
        type = typeof value;
      }
      if (type === 'boolean') {
        value = value ? 'true' : 'false';
      } else if (type === 'object') {
        value = JSON.stringify(value);
      } else if (type === 'json') {
        value = JSON.stringify(value);
      } else if (type === 'model') {
        value = value ? value.id : '-';
      }
    } else {
      value = '-';
    }
    return value;
  };

  const classes = useStyles();

  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleSelectAll = event => {
    let selectedUsers;

    if (event.target.checked) {
      selectedUsers = instances.map(user => user.id);
    } else {
      selectedUsers = [];
    }

    setSelectedUsers(selectedUsers);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedUsers.indexOf(id);
    let newSelectedUsers = [];

    if (selectedIndex === -1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers, id);
    } else if (selectedIndex === 0) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedUsers = newSelectedUsers.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1),
      );
    }

    setSelectedUsers(newSelectedUsers);
  };

  const handlePageChange = (event, page) => {
    modelApi.getState().list({ page });
  };

  const handleRowsPerPageChange = event => {
    modelApi.setState({ pageSize: event.target.value });
  };

  const handleEdit = async event => {
    const id = event.currentTarget.value;
    history.push(`/${schema.routeName}/${id}`);
  };

  const handleDelete = async id => {
    await modelApi.getState().delete(id);
    modelApi.getState().list();
  };

  const handleDeleteSelected = async () => {
    const state = modelApi.getState();
    await Promise.all(
      selectedUsers.map(id => {
        return state.delete(id);
      }),
    );
    setSelectedUsers([]);
    modelApi.getState().list();
  };

  const columnNames = ['id'];
  columnNames.push(...Object.keys(schema.attributes));

  const [confirmItemInstanceId, setConfirmItemInstanceId] = React.useState(null);
  const [confirmAllIsOpen, setConfirmAllOpen] = React.useState(false);

  const handleItemOpen = id => {
    setConfirmItemInstanceId(id);
  };

  const handleItemClose = () => {
    setConfirmItemInstanceId(null);
  };
  const handleAllOpen = () => {
    setConfirmAllOpen(true);
  };

  const handleAllClose = () => {
    setConfirmAllOpen(false);
  };

  const visibleColumns = columnNames.filter(columnName => {
    const attribute = schema.attributes[columnName];
    if (attribute) {
      const hidden = isAttributeHidden(attribute, 'list');
      if (hidden) {
        return false;
      }
    }
    return true;
  });

  const headerColumns = visibleColumns.map((columnName, index) => {
    let sortIcon = '';
    if (sortAttribute === columnName) {
      sortIcon = sortOrder === 'ASC' ? '▲' : '▼';
    }
    return (
      <TableCell
        key={index}
        onClick={() => {
          modelApi.getState().sortBy(columnName);
        }}
      >
        {capitalize(columnName)}
        {sortIcon}
      </TableCell>
    );
  });

  const headerSelection = (
    <div className={classes.headerSelectionToolbar}>
        <Button onClick={handleAllOpen} className={classes.headerSelectionButton}>
          <DeleteIcon />
          Delete
        </Button>

        <AlertDialog
          open={confirmAllIsOpen}
          handleClose={handleAllClose}
          handleConfirm={handleDeleteSelected}
          title={'Are you sure you want to delete the selected items?'}
          text={"They're gone for good afterwards, so please be sure that you want to delete the items."}
        />
    </div>
  );

  const { deleteHidden } = schema;

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <MuiTable>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedUsers.length === instances.length}
                      color="primary"
                      indeterminate={selectedUsers.length > 0 && selectedUsers.length < instances.length}
                      onChange={handleSelectAll}
                    />
                  </TableCell>

                  {headerColumns}

                  {instances.length > 0 && <TableCell padding="checkbox">Actions</TableCell>}
                </TableRow>
              </TableHead>

              <TableBody>
                {instances.map(instance => {
                  return (
                    <TableRow key={instance.id}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedUsers.indexOf(instance.id) !== -1}
                          color="primary"
                          onChange={event => handleSelectOne(event, instance.id)}
                          value="true"
                        />
                      </TableCell>
                      {visibleColumns.map((columnName, index) => {
                        const attribute = schema.attributes[columnName];
                        const value = getValue(instance, columnName, attribute);
                        return <TableCell key={index}>{value}</TableCell>;
                      })}
                      <TableCell style={{ minWidth: '128px' }}>
                        <Box component="div" display="inline">
                          <IconButton onClick={handleEdit} value={instance.id}>
                            <EditIcon />
                          </IconButton>
                        </Box>
                        {!deleteHidden && <Box component="div" display="inline">
                          <IconButton onClick={() => handleItemOpen(instance.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </Box>}
                        <AlertDialog
                          open={confirmItemInstanceId === instance.id}
                          handleClose={handleItemClose}
                          handleConfirm={() => handleDelete(instance.id)}
                          title={'Are you sure you want to delete this item?'}
                          text={"It's gone for good afterwards, so please be sure that you want to delete the item."}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </MuiTable>
            {selectedUsers.length > 0 && headerSelection}
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={totalCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={pageSize}
          rowsPerPageOptions={[5, 10, 25, 100]}
        />
      </CardActions>
    </Card>
  );
};

Table.propTypes = {
  className: PropTypes.string,
};

export default Table;
