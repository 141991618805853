import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Divider,
  FormControlLabel,
  Radio,
  Typography,
  Button,
  RadioGroup,
} from '@material-ui/core';
import debugStore from 'storage/debug';
import { ALL_INTEGRATIONS } from 'services/BuildService';
import { capitalize } from 'helpers';

const useStyles = makeStyles(() => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const CMS = props => {
  const { className, ...rest } = props;
  const [target, setTarget] = useState(debugStore.getTarget());

  const history = useHistory();
  const classes = useStyles();

  const handleChange = event => {
    setTarget(event.target.value);
  };

  const handleSave = () => {
    debugStore.setTarget(target);
    history.push("/");
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form>
        <CardHeader subheader="Configure to which environment the dashboard connects." title="Connection Targets" />
        <Divider />
        <CardContent>
        <Grid className={classes.item} item md={4} sm={6} xs={12}>
          <Typography gutterBottom variant="h6">
            CMS
          </Typography>
          <RadioGroup aria-label="gender" name="gender1" value={target} onChange={handleChange}>
            {ALL_INTEGRATIONS.map(target => {
              return (
                <FormControlLabel
                  key={target}
                  control={<Radio value={target} color="primary" />}
                  label={capitalize(target.toLowerCase())}
                />
              );
            })}
          </RadioGroup>
        </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button color="primary" variant="outlined" onClick={handleSave}>
            Save
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

CMS.propTypes = {
  className: PropTypes.string,
};

export default CMS;
