import io from 'socket.io-client';
import schemapack from './schemapack-parser';
import create from 'zustand';
import { AUTO, DEVELOPMENT, LOCAL, PRODUCTION, buildApi } from 'services/BuildService';
import debugStore from 'storage/debug';

const ENDPOINTS = {
  [LOCAL]: 'http://localhost:5000/',
  [DEVELOPMENT]: 'https://develop.hub.rolandberger.dmdr.io/',
  [PRODUCTION]: 'https://hub.rolandberger.dmdr.io/',
};

const INTEGRATION_TARGETS = {
  [LOCAL]: ENDPOINTS[DEVELOPMENT],
  [DEVELOPMENT]: ENDPOINTS[DEVELOPMENT],
  [PRODUCTION]: ENDPOINTS[PRODUCTION],
};

export const [useSocketStore, socketApi] = create((set, get) => ({
  socket: null,
  url: '',
  connect: () => {
    const { integration } = buildApi.getState();
    const debugSocketTarget = debugStore.getTarget();
    const url = debugSocketTarget === AUTO ? INTEGRATION_TARGETS[integration] : ENDPOINTS[debugSocketTarget];
    const socket = io(url + '?protocol=v6&admin=true', {
      parser: schemapack,
      reconnectionAttempts: 2,
      reconnectionDelay: 250,
    });
    set({ url, socket });
    return socket;
  },

  disconnect: () => {
    const { socket } = get();
    if (socket) {
      socket.removeAllListeners();
      socket.disconnect();
    }
    set({ url: '', socket: null });
  },
}));
