import create from 'zustand';
import { cmsApi } from 'services/CmsService';
import axios from 'axios';
import userStore from 'storage/user';

function context() {
  const { endpoint } = cmsApi.getState();
  const token = userStore.getToken();
  return { endpoint };
}

export const [useEventStore, eventApi] = create((set, get) => ({
  instance: null,

  pick: async () => {
    let  event = get().instance;
    if(event) {
      return event;
    }
    const { endpoint } = context();
    const result = await axios.post(`${endpoint}/events/pick`);
    const instance = result.data ? result.data.event : null;
    set({ instance });
    return instance;
  },

}));
