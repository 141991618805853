import { capitalize } from 'helpers';
import { modelApi } from 'services/ModelService';
import { Button } from '@material-ui/core';
import React from 'react';


export function FileField(params) {
  const { instance, attributeName } = params;

  const label = 'Select ' + capitalize(attributeName);

  const handleFileClick = () => {
    const input = document.createElement('input');
    input.onchange = e => {
      const file = e.target.files[0];
      instance[attributeName] = file;
      instance['fileName'] = file.name;
      modelApi.setState({ instance: { ...instance } });
    };
    input.type = 'file';
    input.click();
  };

  return (
    <Button color="secondary" variant="contained" onClick={handleFileClick}>
      {label}
    </Button>
  );
};
