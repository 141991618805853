import { Box, LinearProgress, Typography } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles(theme => ({
  loader: {
    margin: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
}));

export function Loader({ progress }) {
  const classes = useStyles();
  return (
    <Box className={classes.loader}>
      <Box width="48%" mr={1}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          progress,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
