import create from 'utilities/zustand/create';

export const AUTO = 'AUTO';
export const LOCAL = 'LOCAL';
export const DEVELOPMENT = 'DEVELOPMENT';
export const PRODUCTION = 'PRODUCTION';
export const ALL_INTEGRATIONS = [AUTO, LOCAL, DEVELOPMENT, PRODUCTION];

const INTEGRATIONS = {
  // eslint-disable-next-line no-useless-computed-key
  ['localhost']: LOCAL,
  // eslint-disable-next-line no-useless-computed-key
  ['develop.dashboard.rolandberger.dmdr.io']: DEVELOPMENT,
  // eslint-disable-next-line no-useless-computed-key
  ['dashboard.rolandberger.dmdr.io']: PRODUCTION,
};

export const [useBuildStore, buildApi] = create(module, set => ({
  integration: '',
  init: () => {
    const integration = INTEGRATIONS[window.location.hostname] || DEVELOPMENT;
    set({ integration });
  },
}));
