import Storage from 'utilities/storage';

const TARGET = 'CONNECTION_TARGET';

const storage = new Storage();
storage.init('live_debug_1');
storage.defineEntry(TARGET, 'AUTO');

export default {
  setTarget: target => {
    storage.setItem(TARGET, target);
  },
  getTarget: () => {
    return storage.getItem(TARGET);
  },
};
