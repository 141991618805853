import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: theme.spacing(1),
  },
}));

const Profile = props => {
  const { className, user, ...rest } = props;

  const classes = useStyles();

  const data = {
    name: user.company || user.username,
    avatar: user.thumbnailUrl || '/images/avatars/placeholder.png',
    bio: user.title,
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Avatar alt="Person" className={classes.avatar} src={data.avatar} to="/settings" />
      <Typography className={classes.name} variant="h4">
        {data.name}
      </Typography>
      <Typography variant="body2">{data.bio}</Typography>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
};

export default Profile;
