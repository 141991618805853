import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Paper, Input } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { modelApi } from 'services/ModelService';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    flexBasis: 420,
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  input: {
    flexGrow: 1,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '-0.05px',
  },
}));

const SearchInput = props => {
  const timer = useRef();
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const { className, style, ...rest } = props;
  const classes = useStyles();

  useEffect(() => {
    if (timer.current) clearTimeout(timer.current);

    if (!loading) {
      setLoading(true);
    }

    timer.current = setTimeout(async () => {
      const query = encodeURIComponent(input);
      const state = modelApi.getState();
      if(!state.schema){
        return;
      }
      await state.list({ query });
      setLoading(false);
    }, 300);
  }, [input]);

  const handleChange = e => {
    if (e.nativeEvent.inputType === 'insertLineBreak') {
      return;
    }
    const input = e.nativeEvent.target.value;
    setInput(input);
  };

  return (
    <Paper {...rest} className={clsx(classes.root, className)} style={style}>
      <SearchIcon className={classes.icon} />
      <Input {...rest} className={classes.input} disableUnderline onChange={handleChange} value={input} />
      {loading && <CircularProgress color="inherit" size={20} />}
    </Paper>
  );
};

SearchInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object,
};

export default SearchInput;
