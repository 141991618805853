import create from 'zustand';
import { AUTO, DEVELOPMENT, LOCAL, PRODUCTION, buildApi } from 'services/BuildService';
import debugStore from 'storage/debug';

const ENDPOINTS = {
  [LOCAL]: 'http://localhost:1337',
  [DEVELOPMENT]: 'https://develop.strapi.rolandberger.dmdr.io:443',
  [PRODUCTION]: 'https://strapi.rolandberger.dmdr.io:443',
};

const INTEGRATION_TARGETS = {
  [AUTO]: ENDPOINTS[DEVELOPMENT],
  [LOCAL]: ENDPOINTS[DEVELOPMENT],
  [DEVELOPMENT]: ENDPOINTS[DEVELOPMENT],
  [PRODUCTION]: ENDPOINTS[PRODUCTION],
};

export const [useCmsStore, cmsApi] = create(set => ({
  endpoint: '',
  init: () => {
    const { integration } = buildApi.getState();
    const debugEndpointTarget = debugStore.getTarget();
    const endpoint = debugEndpointTarget === AUTO ? INTEGRATION_TARGETS[integration] : ENDPOINTS[debugEndpointTarget];
    set({ endpoint });
  },
}));
