import create from 'zustand';
import { cmsApi } from 'services/CmsService';
import axios from 'axios';
import userStore from 'storage/user';
import { eventApi } from 'services/EventService';

function context() {
  const { endpoint } = cmsApi.getState();
  const token = userStore.getToken();
  const auth = { headers: { Authorization: `Bearer ${token}` } };
  return { endpoint, auth };
}

export const [useTalkStore, talkApi] = create((set, get) => ({
  instance: null,

  findAll: async (location) => {
    const { endpoint, auth } = context();
    const event = await eventApi.getState().pick();
    const result = await axios.get(`${endpoint}/talks?event=${event.id}&location=${location}&isHidden=false`, auth);
    const instance = result.data;
    set({ instance });
    return instance;
  },

}));
