import create from 'zustand';
import { cmsApi } from 'services/CmsService';
import axios from 'axios';
import userStore from '../../storage/user';
import config from 'services/ModelService/config';
import merge from 'deepmerge';
import moment from 'moment';
import { notificationApi } from 'services/NotificationService';

function context() {
  const { endpoint } = cmsApi.getState();
  const token = userStore.getToken();
  const auth = { headers: { Authorization: `Bearer ${token}` } };
  return { endpoint, auth };
}

export function isAttributeHidden(attribute, context) {
  if (attribute.hidden == null) {
    // visible by default
    return false;
  }

  let hidden = attribute.hidden;

  if (typeof hidden === 'object') {
    hidden = attribute.hidden[context];
  }

  if (typeof hidden === 'function') {
    return hidden();
  }

  return hidden;
}

export const [useModelStore, modelApi] = create((set, get) => ({
  schema: null,
  instance: null,
  instances: [],

  page: 0,
  query: null,
  pageSize: 10,
  totalCount: 0,

  sortAttribute: 'id',
  sortOrder: 'ASC',

  status: { status: "done" },

  init: async (modelName, routeName) => {
    const { endpoint, auth } = context();
    const configSchema = config[modelName];
    const apiName = (configSchema ? configSchema.apiName : null) || modelName;
    const result = await axios.get(`${endpoint}/${apiName}/schema`, auth);
    let schema = result.data;
    schema.modelName = modelName;
    schema.routeName = routeName;
    schema.apiName = apiName;
    if (Object.prototype.hasOwnProperty.call(config, modelName)) {
      schema = merge(schema, configSchema);
    }
    set({
      schema,
      page: 0,
      totalCount: 0,
      sortAttribute: 'id',
      sortOrder: 'ASC',
    });
  },

  shutdown: () => {
    set({
      schema: null,
      instance: null,
      instances: [],
    });
  },

  new: async () => {
    const { schema } = get();
    const instance = {};
    const date = new Date();
    const next5Minutes = moment(date).add(5, 'minutes');
    next5Minutes.minutes(Math.floor(next5Minutes.minutes() / 5) * 5);
    next5Minutes.seconds(0);
    next5Minutes.milliseconds(0);
    next5Minutes.format('HH:mm');
    for (const key of Object.keys(schema.attributes)) {
      const attribute = schema.attributes[key];
      const hidden = isAttributeHidden(attribute, 'form');
      if (hidden && attribute.default == null) {
        continue;
      }
      if (attribute.default) {
        if (typeof attribute.default === 'function') {
          instance[key] = await attribute.default();
        } else {
          instance[key] = attribute.default;
        }
      } else {
        switch (attribute.type) {
          case 'boolean':
            instance[key] = attribute.default || false;
            break;
          case 'string':
            instance[key] = attribute.default || '';
            break;
          case 'integer':
            instance[key] = attribute.default || 0;
            break;
          case 'datetime':
            instance[key] = next5Minutes;
            break;
          default:
            // eslint-disable-next-line no-console
            console.warn('could not create field:', key, attribute.type);
        }
      }
    }
    set({
      instance,
    });
  },

  cancel: () => {
    set({ instance: null, instances: [] });
  },

  sortBy: attribute => {
    let { sortAttribute, sortOrder } = get();
    if (sortAttribute === attribute) {
      sortOrder = sortOrder === 'ASC' ? 'DESC' : 'ASC';
    } else {
      sortAttribute = attribute;
    }
    set({ sortAttribute, sortOrder });
    get().list();
  },

  list: async ({ page, query } = {}) => {
    const { schema, pageSize, sortAttribute, sortOrder } = get();
    const { endpoint, auth } = context();
    if (page == null) {
      page = get().page;
    }
    if (query == null) {
      query = get().query;
    }
    const totalCount = await axios.get(
      `${endpoint}/${schema.apiName}/count${schema.filter ? '?' + schema.filter : ''}`,
      auth,
    );
    // @TODO: handle multiple fields
    const searchField = schema.searchFields && schema.searchFields.length === 1 ? schema.searchFields[0] : null;
    const result = await axios.get(
      `${endpoint}/${schema.apiName}?_start=${page * pageSize}&_limit=${pageSize}&_sort=${sortAttribute}:${sortOrder}${
      schema.filter ? '&' + schema.filter : ''
      }${query && searchField ? `&${searchField}_contains=${query}` : ''}`,
      auth,
    );
    set({ instances: result.data, page, query, totalCount: totalCount.data });
  },

  options: async (model, filter) => {
    const { endpoint, auth } = context();
    const response = await axios.get(`${endpoint}/${model}?_limit=-1&_sort=id${filter ? '&' + filter : ''}`, auth);
    return response.data;
  },

  get: async id => {
    const { schema } = get();
    const { endpoint, auth } = context();

    const isSingleType = schema.kind === 'singleType';
    const url = isSingleType ? `${endpoint}/${schema.apiName}` : `${endpoint}/${schema.apiName}/${id}`;
    const result = await axios.get(url, auth);
    const instance = result.data;
    set({ instance });
    return instance;
  },

  create: async instance => {
    try {
      const { schema } = get();
      const { endpoint, auth } = context();
      if (schema.useFormData) {
        const form = new FormData();
        const data = JSON.stringify(instance);
        form.append('data', data);
        if (instance.file) form.append('file', instance.file);
        await axios.post(`${endpoint}/${schema.apiName}`, form, {
          ...auth,
          onUploadProgress: c => {
            set({status: { status: "pending", progress: c.loaded, length: c.total}})
          },
        });
      } else {
        await axios.post(`${endpoint}/${schema.apiName}`, instance, auth);
      }
    } catch (e) {
      notificationApi.getState().showError(e.message);
      throw e;
    }
  },

  update: async instance => {
    try {
      const { schema } = get();
      const { endpoint, auth } = context();

      const update = { ...instance };
      // skip hidden fields in updates, suppose that should always be the case
      for (const key of Object.keys(schema.attributes)) {
        const attribute = schema.attributes[key];
        const hidden = isAttributeHidden(attribute, 'form');
        if (hidden) {
          delete update[key];
        }
      }

      const isSingleType = schema.kind === 'singleType';
      const url = isSingleType ? `${endpoint}/${schema.apiName}` : `${endpoint}/${schema.apiName}/${instance.id}`;

      if (schema.useFormData) {
        const form = new FormData();
        const data = JSON.stringify(update);
        form.append('data', data);
        if (instance.file) form.append('file', instance.file);
        await axios.put(url, form, {
          ...auth,
          onUploadProgress: c => {
            set({status: { status: "pending", progress: c.loaded, length: c.total}})
          },
        });

      } else {
        await axios.put(url, update, auth);
      }
    } catch (e) {
      notificationApi.getState().showError(e.message);
      throw e;
    }
  },

  delete: async id => {
    const { schema } = get();
    const { endpoint, auth } = context();
    await axios.delete(`${endpoint}/${schema.apiName}/${id}`, auth);
  },
}));
