import React from 'react';
import { makeStyles } from '@material-ui/styles';

import Synchronize from './components/Synchronize';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const SynchronizeTool = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Synchronize/>
    </div>
  );
};

export default SynchronizeTool;
