/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, Link, List, ListSubheader, makeStyles } from '@material-ui/core';

import {
  Globe as GlobeIcon,
  Calendar as CalendarIcon,
  AlertCircle as AlertCircleIcon,
  AlignLeft as AlignLeftIcon,
  Settings as SettingsIcon,
  RefreshCcw as RefreshCcwIcon,
  Users as UsersIcon,
  File as FileIcon,
  Server as ServerIcon,
  Cast as CastIcon,
  Tv as TvIcon,
  Zap as ZapIcon,
  Film as FilmIcon,
  Tool as ToolIcon,
  Image as ImageIcon,
  Volume as VolumeIcon,
  Book as BookIcon,
  BookOpen as BookOpenIcon,
  BarChart2 as BarChart2Icon,
  Database as DatabaseIcon,
  Anchor as AnchorIcon,
  Send as SendIcon,
  Watch as WatchIcon
} from 'react-feather';

import { useUserStore } from 'services/UserService';

import NavItem from './NavItem';

const config = {
  title: 'config',
  icon: DatabaseIcon,
  href: '/config',
};

const adminNavConfig = [
  {
    subheader: 'Reports',
    items: [
      {
        title: 'Dashboard',
        icon: AlertCircleIcon,
        href: '/dashboard',
      },
    ],
  },
  {
    subheader: 'Content',
    items: [
      {
        title: 'Application',
        icon: GlobeIcon,
        href: '/application',
      },
      {
        title: 'Pre-Event Pages',
        icon: WatchIcon,
        href: '/pre-event-pages',
      },
      {
        title: 'Events',
        icon: CalendarIcon,
        href: '/events',
      },
      {
        title: 'Users',
        icon: UsersIcon,
        href: '/users',
      },
      {
        title: 'Arena',
        icon: TvIcon,
        items: [
          {
            title: 'Live Streams',
            icon: CastIcon,
            href: '/streams',
          },
          {
            title: 'Director',
            icon: ZapIcon,
            href: '/director',
          },
        ],
      },
      {
        title: 'Library',
        icon: BookOpenIcon,
        items: [
          {
            title: 'Libraries',
            icon: ServerIcon,
            href: '/resource-hubs',
          },
          {
            title: 'Categories',
            icon: AlignLeftIcon,
            href: '/resource-categories',
          },
          {
            title: 'Resources',
            icon: FileIcon,
            href: '/resources',
          },
        ],
      },
      {
        title: 'Playground',
        icon: SendIcon,
        href: '/playgrounds',
        items: [
          {
            title: 'Playgrounds',
            icon: ServerIcon,
            href: '/playgrounds',
          },
          {
            title: 'Links',
            icon: AnchorIcon,
            href: '/playground-resources',
          },
        ],
      },
    ],
  },
  {
    subheader: 'Resources',
    icon: FileIcon,
    items: [
          {
            title: 'Images',
            icon: ImageIcon,
            href: '/resource-images',
          },
          {
            title: 'Videos',
            icon: FilmIcon,
            href: '/resource-videos',
          },
          {
            title: 'Audio',
            icon: VolumeIcon,
            href: '/resource-podcasts',
          },
          {
            title: 'Documents',
            icon: BookIcon,
            href: '/resource-documents',
          },
          {
            title: 'Other',
            icon: BarChart2Icon,
            href: '/resource-others',
          },
        ],
  },
  {
    subheader: 'Administration',
    items: [
      {
        title: 'Setup',
        icon: SettingsIcon,
        items: [
          {
            title: 'MEA',
            href: '/mea',
          },
          {
            title: 'Zoom',
            href: '/zoom',
          },
          {
            title: 'S3',
            href: '/s-3',
          },
        ],
      },
      {
        title: 'Synchronize',
        icon: RefreshCcwIcon,
        href: '/synchronize',
      },
      {
        title: 'Tools',
        icon: ToolIcon,
        href: '/admin',
      },
    ],
  },
];
const directorNavConfig = [
  {
    subheader: 'Reports',
    items: [
      {
        title: 'Dashboard',
        icon: AlertCircleIcon,
        href: '/dashboard',
      },
    ],
  },
  {
    subheader: 'Content',
    items: [
      {
        title: 'Live Streams',
        icon: CastIcon,
        href: '/streams',
      },
      {
        title: 'Director',
        icon: ZapIcon,
        href: '/director',
      },
    ],
  },
];

const loggedOutConfig = [
  {
    subheader: 'Administration',
    items: [config],
  },
];

function renderNavItems({ items, ...rest }) {
  return <List disablePadding>{items.reduce((acc, item) => reduceChildRoutes({ acc, item, ...rest }), [])}</List>;
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem depth={depth} icon={item.icon} key={key} info={item.info} open={Boolean(open)} title={item.title}>
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>,
    );
  } else {
    acc.push(<NavItem depth={depth} href={item.href} icon={item.icon} key={key} info={item.info} title={item.title} />);
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 206,
    height: 'calc(100% - 206px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const isLoggedIn = useUserStore(state => state.loginState === 'LOGGED_IN');
  const user = useUserStore(state => state.user);

  let config;

  if (isLoggedIn) {
    config = user.role.type === 'admin' ? adminNavConfig : directorNavConfig;
  } else {
    config = loggedOutConfig;
  }

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Divider />
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          {config.map(config => (
            <List
              key={config.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {config.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({ items: config.items, pathname: location.pathname })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
        {content}
      </Drawer>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
