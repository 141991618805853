import React from 'react';
import moment from 'moment-timezone';
import { capitalize, localToUtc, utcToLocal } from 'helpers';
import { modelApi } from 'services/ModelService';
import { DateTimePicker } from '@material-ui/pickers';

export function DateTimeField(params) {
  const { instance, attributeName, timezone } = params;

  const label = capitalize(attributeName);

  const tz = timezone ? timezone  : moment.tz.guess();
  const localTime = instance[attributeName] ? utcToLocal(instance[attributeName], tz) : null;

  const handleChange = timeStamp => {
    instance[attributeName] = timeStamp == null ? null : localToUtc(timeStamp, tz);
    modelApi.setState({ instance: { ...instance } });
  };

  return (
    <DateTimePicker
      label={`${label} (${tz})`}
      inputVariant="outlined"
      value={localTime}
      onChange={handleChange}
      minutesStep={5}
      fullWidth
      clearable
    />
  );
};
