import create from 'utilities/zustand/create';

let socket = null;

export const [useHubStore, hubApi] = create(module, (set, get) => ({
  init: managedSocket => {
    socket = managedSocket;
  },

  reloadClients: async () => {
    return new Promise((resolve) => {
      socket.emit('admin/reload', result => {
        resolve(result);
      });
    });
  },
  requestStatistics: async () => {
    return new Promise((resolve) => {
      socket.emit('admin/statistics', result => {
        resolve(result);
      });
    });
  },
}));
