import React from 'react';
import { Button, Card, CardActions, CardHeader, Divider } from '@material-ui/core';
import useCMS from '../useCMS';
import { PRODUCTION } from 'services/BuildService';
import debugStore from 'storage/debug';
import axios from 'axios';
import { hubApi } from '../../../services/HubService';

const Admin = () => {
  const { endpoint, auth } = useCMS();

  const syncEverything = async () => {
    if (window.confirm("Please confirm that you want to delete the all chats and no event is currently running.")) {

      const target = debugStore.getTarget();

      if (target !== PRODUCTION || window.confirm("It is the PRODUCTION environment. I do consent.")) {
        await axios.post(`${endpoint}/chats/clear`, {}, auth);
        await hubApi.getState().reloadClients();
      }
    }
  };

  return (
    <Card>
      <form>
        <CardHeader title="Chat" subheader="Delete all chats in the database (event, private, support and group chats). Following this, we trigger a refresh signal to all connected clients." />
        <Divider />
        <CardActions>
          <Button color="primary" variant="outlined" onClick={syncEverything}>
            I Understand The Consequences
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default Admin;
